import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Template = ({
  imgSrc,
  templateName,
  handleApplyTemplate,
  isInUse = false,
}) => {
  return (
    <Grid item xs={12} md={6} mb={{ xs: 1, md: 2 }} height="fit-content">
      <Box display="flex" alignItems="center">
        <Typography
          ml="2%"
          mr={2}
          as="h2"
          color="#3A3A3A"
          fontSize="20px"
          fontWeight="600"
        >
          {templateName}
        </Typography>
        {isInUse && (
          <>
            <CheckCircleIcon
              color="primary"
              sx={{ marginRight: 1, fontSize: 24 }}
            />{' '}
            <Typography as="span" color="primary" fontWeight={500}>
              Plantilla en uso
            </Typography>
          </>
        )}
      </Box>
      <Box width="100%" mb={1}>
        <Box
          component="img"
          src={imgSrc}
          alt={templateName}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
      {!isInUse && (
        <Box
          display="flex"
          justifyContent={{ xs: 'center', md: 'flex-end' }}
          width="96%"
          m="0 auto"
        >
          <Button
            variant="contained"
            sx={{ width: { xs: '100%', md: 'unset' } }}
            onClick={handleApplyTemplate}
          >
            Probar plantilla
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default Template;
