import React from 'react';
import Template from './Template';
import { Grid } from '@mui/material';
import { templateObjects } from './templateObjects';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../../components/amplitude';
import { getTemplateName } from '../../../other/getTemplateName';

const TemplatesContainer = () => {
  const shop = useSelector((state) => state.tienda);
  const router = useHistory();
  const selectedTemplate = localStorage.getItem(
    `template-selected-${shop.idTienda}`
  );

  const handleApplyTemplate = (templateId) => {
    localStorage.setItem(`template-selected-${shop.idTienda}`, `${templateId}`);
    router.push('/builder', { from: '/selecciona-plantilla' });
    createAmplitudeEventWithDevice(
      design_amplitude_events.diseño_probar_plantilla.event_name,
      {
        plantilla: getTemplateName(templateId),
      }
    );
  };

  return (
    <Grid container justifyContent="space-between" spacing={6} mb={10}>
      {templateObjects.map((template) => (
        <Template
          key={template.id}
          imgSrc={template.src}
          templateName={template.templateName}
          handleApplyTemplate={() => handleApplyTemplate(template.id)}
          isInUse={
            selectedTemplate
              ? template.id === Number(selectedTemplate)
              : template.id === shop.Templates_idTemplates
          }
        />
      ))}
    </Grid>
  );
};

export default TemplatesContainer;
