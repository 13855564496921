import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  tiendaObtenerUltimoPago,
  tiendaObtenerAlertaMasiva,
  ventaObtenerVentas,
} from '../../store/actions';
import queryString from 'query-string';
import { Box } from '@mui/material';
import { MassiveAlertUI, ToggleUI } from '../../components/common';
import { Onboarding } from './Onboarding';
import { WrapperLayout } from '../../layouts/WrapperLayout';
import { useHistory } from 'react-router-dom';
import { SuscriptionBanner } from './SuscriptionBanner';
import { getHomeMessages } from './getHomeMessages';
import { showAlertUI } from '../../store/actions/alertActions';
import { VideoTutorial } from './VideoTutorial';
import { ShopInformationStatus } from './ShopInformationStatus';
import { isNumber } from 'lodash';
import NotificationsCenter from './NotificationsCenter';
import { getNotificationData } from './getNotificationData';
import moment from 'moment';
import { Config } from '../../other';

const HomePage_ = ({
  getSales,
  location,
  dispatchAlert,
  getLastPayment,
  getMassiveAlert,
}) => {
  const { shopData, massiveAlert } = useSelector((state) => ({
    shopData: state.tienda,
    massiveAlert: state.alerta,
  }));
  const history = useHistory();

  const [isGetSalesLoading, setIsGetSalesLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isGetLastPaymentLoading, setIsGetLastPaymentLoading] = useState(true);
  const [isGetMassiveAlertLoading, setIsGetMassiveAlertLoading] =
    useState(true);
  const [onboardingStepsCompleted, setOnboardingStepsCompleted] =
    useState(false);
  const [allRequestsLoaded, setAllRequestsLoaded] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  const [state, setState] = useState({
    salesAmount: null,
    baja: false,
    proximo_vencimiento: {
      id: 0,
      monto: 0,
      fecha_vencimiento: null,
      dias_vencimiento: 0,
      fecha_corte: null,
      dias_corte: 0,
      paga: 0,
      cerrada: 0,
      fecha_pago: null,
      periodo_prueba: 0,
    },
    overview: {
      ventas_activas: 0,
      envios_pendientes: 0,
      cobros_pendientes: 0,
      cantidad_clientes: 0,
    },
  });

  const fromDate = moment(moment().startOf('day').subtract(10, 'days'))
    .utc()
    .format(Config.DATETIME_FORMAT);

  const untilDate = moment(moment().endOf('day'))
    .utc()
    .format(Config.DATETIME_FORMAT);

  useEffect(() => {
    if (localStorage.getItem('show-alert')) {
      const showAlert = JSON.parse(localStorage.getItem('show-alert'));
      dispatchAlert(showAlert);
      localStorage.removeItem('show-alert');
    }

    const { nombre, dominio } = queryString.parse(location.search);
    if (nombre && dominio) {
      history.push('/');
    }

    getSales(
      {
        page: 0,
        v_archivada: 0,
        desde: fromDate,
        hasta: untilDate,
      },
      (err, resp) => {
        setIsError((prev) => {
          return prev || err;
        });
        const sales = resp.data;
        let shipmentPendingAmount = 0;
        let paymentPendingAmount = 0;

        sales.forEach((sale) => {
          if (sale.v_estado_pago === 0) {
            paymentPendingAmount++;
          }

          if (sale.v_estado_envio === 0 && sale.v_estado_pago === 1) {
            shipmentPendingAmount++;
          }
        });

        setState((prevState) => ({
          ...prevState,
          overview: {
            envios_pendientes: shipmentPendingAmount,
            cobros_pendientes: paymentPendingAmount,
          },
        }));

        setIsGetSalesLoading(false);
      }
    );

    getLastPayment((err, resp) => {
      setIsError((prev) => prev || err);
      setState((prevState) => ({
        ...prevState,
        proximo_vencimiento: resp.data,
      }));

      setIsGetLastPaymentLoading(false);
    });

    if (massiveAlert.close === false) {
      getMassiveAlert(() => {
        setIsGetMassiveAlertLoading(false);
      });
    } else {
      setIsGetMassiveAlertLoading(false);
    }
  }, [history, massiveAlert.close, location.search]);

  useEffect(() => {
    if (
      shopData.t_prueba === 1 &&
      shopData.t_debito_automatico === 0 &&
      state.proximo_vencimiento &&
      state.proximo_vencimiento.dias_vencimiento > 10 &&
      state.proximo_vencimiento.dias_vencimiento < 31 &&
      localStorage.getItem('showSuscriptionBanner') === null
    ) {
      localStorage.setItem('showSuscriptionBanner', true);
    }

    if (isNumber(shopData?.t_offmode)) {
      setState((prevState) => ({
        ...prevState,
        baja: shopData.t_offmode === 2 ? true : false,
      }));
    }
  }, [
    shopData.t_prueba,
    shopData.t_offmode,
    state.proximo_vencimiento,
    shopData.t_debito_automatico,
  ]);

  const handleOnboardingError = (err) => {
    setIsError((prev) => prev || err);
  };

  const setSalesAmount = (salesAmount) => {
    setState((prevState) => ({
      ...prevState,
      salesAmount: salesAmount,
    }));
  };

  const homeMessages = getHomeMessages(state, shopData);
  const notificationData = getNotificationData(state.overview);
  const mbContainer = !(homeMessages.showBanner && isBannerOpen)
    ? 0
    : { xs: '195px', md: '70px' };

  return (
    <WrapperLayout
      loading={
        isGetSalesLoading || isGetLastPaymentLoading || isGetMassiveAlertLoading
      }
      error={isError}
    >
      <Box
        mb={mbContainer}
        sx={{
          rowGap: '24px',
          display: 'grid',
          columnGap: { xs: '12px', md: '24px' },
          gridTemplateColumns: { xs: 'repeat(4,1fr)', md: 'repeat(12,1fr)' },
        }}
      >
        <MassiveAlertUI offMode={!state.baja} />

        <ShopInformationStatus nameStore={shopData.t_nombre} />

        <VideoTutorial
          salesAmount={state.salesAmount}
          allRequestsLoaded={allRequestsLoaded}
          onboardingStepsCompleted={onboardingStepsCompleted}
        />

        <Onboarding
          salesAmount={state.salesAmount}
          setSalesAmount={setSalesAmount}
          setOnboardingStepsCompleted={setOnboardingStepsCompleted}
          setAllRequestsLoaded={setAllRequestsLoaded}
          onboardingStepsCompleted={onboardingStepsCompleted}
          allRequestsLoaded={allRequestsLoaded}
          handleOnboardingError={handleOnboardingError}
        />

        <ToggleUI show={state.salesAmount > 0}>
          <NotificationsCenter
            notificationData={notificationData}
            fromDate={fromDate}
            untilDate={untilDate}
          />
        </ToggleUI>
      </Box>

      <SuscriptionBanner
        messages={homeMessages}
        isBannerOpen={isBannerOpen}
        setIsBannerOpen={setIsBannerOpen}
      />
    </WrapperLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSales: (data, callback) => dispatch(ventaObtenerVentas(data, callback)),
    getLastPayment: (callback) => dispatch(tiendaObtenerUltimoPago(callback)),
    getMassiveAlert: (callback) =>
      dispatch(tiendaObtenerAlertaMasiva(callback)),
    dispatchAlert: (data) => dispatch(showAlertUI(data)),
  };
};

const HomePage = connect(null, mapDispatchToProps)(HomePage_);

export default HomePage;
