import React from 'react';
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import {
  ShopSettings,
  TemplateSettings,
  BlockSettings,
  BlockEdit,
  ShopProductsSettings,
  ShopCheckoutSettings,
} from '.';
import { withStyles } from '@mui/styles';
import {
  createAmplitudeEventWithDevice,
  design_amplitude_events,
} from '../../../components/amplitude';
import { useHistory } from 'react-router-dom';

const DrawerContent_ = ({
  active_page,
  tienda,
  settings,
  agregar,
  selects,
  bloques,
  productos,
  handleChange,
  handleChangeBlock,
  handleBorrarBlock,
  handleChangePage,
  handleDisableEnableBlock,
  handleChangeSelect,
  handleChangeSelectBlock,
  handleAgregarBlock,
  handleOrdenarBlocks,
  handleChangeFileBlock,
  handleChangeGridDesign,
  handleChangeFileSettings,
  handleChangeFeedProducts,
  handleChangeProductsBlock,
  handlerShowAlertUI,
  classes,
}) => {
  const history = useHistory();

  const renderTabContent = () => {
    if (history.location.state?.from === '/selecciona-plantilla') {
      createAmplitudeEventWithDevice(
        design_amplitude_events.diseño_selecciona_plantillas.event_name
      );
      history.replace({ state: {} });
      handleChangePage(null, -3);
      return;
    }

    switch (active_page) {
      // personalizacion
      case -1:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_personalizacion.event_name
        );
        return (
          <ShopSettings
            tienda={tienda}
            settings={settings}
            selects={selects}
            handleChange={handleChange}
            handlerShowAlertUI={handlerShowAlertUI}
            handleChangeSelect={handleChangeSelect}
            handleChangeFileSettings={handleChangeFileSettings}
          />
        );

      // secciones
      case -2:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_secciones.event_name
        );
        return (
          <BlockSettings
            bloques={bloques}
            agregar={agregar}
            selects={selects}
            handleChangeSelect={handleChangeSelect}
            handleOrdenarBlocks={handleOrdenarBlocks}
            handleChangePage={handleChangePage}
            handleBorrarBlock={handleBorrarBlock}
            handleAgregarBlock={handleAgregarBlock}
            handleDisableEnableBlock={handleDisableEnableBlock}
          />
        );

      // plantillas
      case -3:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_plantillas.event_name
        );
        return <TemplateSettings templates={selects.templates} />;

      //productos -> productos conf
      case -5:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_productos.event_name
        );
        return (
          <ShopProductsSettings
            tienda={tienda}
            selects={selects}
            settings={settings}
            productos={productos}
            handleChange={handleChange}
            handlerShowAlertUI={handlerShowAlertUI}
            handleChangeSelect={handleChangeSelect}
            handleChangeFeedProducts={handleChangeFeedProducts}
          />
        );

      //checkout
      case -6:
        createAmplitudeEventWithDevice(
          design_amplitude_events.diseño_selecciona_checkout.event_name
        );
        return (
          <ShopCheckoutSettings
            settings={settings}
            selects={selects}
            handleChangeSelect={handleChangeSelect}
          />
        );

      //inicio -> bloques
      default:
        return (
          <BlockEdit
            template_id={settings.template_id.value}
            active_page={active_page}
            bloques={bloques}
            selects={selects}
            handleChangeBlock={handleChangeBlock}
            handleChangeFileBlock={handleChangeFileBlock}
            handleChangeSelectBlock={handleChangeSelectBlock}
            handleChangePage={handleChangePage}
            handleChangeGridDesign={handleChangeGridDesign}
            handleChangeProductsBlock={handleChangeProductsBlock}
            handlerShowAlertUI={handlerShowAlertUI}
          />
        );
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.card_content_tab }}>
          <Tabs
            value={
              history.location.state?.from === '/selecciona-plantilla'
                ? -3
                : active_page >= 0
                ? -2
                : active_page
            }
            scrollButtons
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              scrollButtons: classes.scrollButtons,
            }}
            onChange={(e, value) => handleChangePage(e, value)}
            allowScrollButtonsMobile
          >
            {selects.macro_pages.map((el, key) => (
              <Tab
                key={key}
                label={el.label}
                value={el.value}
                classes={{
                  root: classes.tabItem,
                }}
              />
            ))}
          </Tabs>
        </CardContent>
      </Card>
      <Box
        sx={{
          boxSizing: 'border-box',
          padding: '20px 12px',
        }}
      >
        {renderTabContent()}
      </Box>
    </>
  );
};

const styles = () => ({
  scrollButtons: {
    width: 'auto',
    backgroundColor: '#F2F2F2',
  },
  tabItem: {
    fontSize: '0.8125rem',
    padding: '21.25px 24px',
    minWidth: 'auto',
    backgroundColor: '#FAFAFA',
    textTransform: 'none',
  },
  card: {
    margin: '12px 12px 0',
  },
  card_content_tab: {
    padding: '0 !important',
  },
});

export const DrawerContent = withStyles(styles, { withTheme: true })(
  DrawerContent_
);
