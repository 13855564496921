import React from 'react';
import { CardModuleUI } from '../../../components/common';
import { Box, Typography } from '@mui/material';
import { Paragraph } from './styles';
import { Link, useHistory } from 'react-router-dom';
import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import NotificationItem from './NotificationItem';

const NotificationsCenter = ({ notificationData, fromDate, untilDate }) => {
  const router = useHistory();
  const { title, subtitle, items, aditionalText } = notificationData;

  const handleOnClick = (type) => {
    const notificationItems = {
      payment: {
        amplitudeEvent: amplitude_events.cobros_pendientes.event_name,
        url: `/listado-de-ventas?v_estado_pago=0&v_metodo_pago=0&v_metodo_envio=0&v_estado_envio=-1&v_archivada=0&v_mayorista=-1&v_factura=-1&desde=${fromDate}&hasta=${untilDate}`,
      },
      shipment: {
        amplitudeEvent: amplitude_events.envios_pendientes.event_name,
        url: `/listado-de-ventas?v_estado_pago=1&v_metodo_pago=0&v_metodo_envio=0&v_estado_envio=0&v_archivada=0&v_mayorista=-1&v_factura=-1&desde=${fromDate}&hasta=${untilDate}`,
      },
    };

    createAmplitudeEventWithDevice(notificationItems[type].amplitudeEvent);
    router.push(notificationItems[type].url);
  };

  return (
    <Box
      sx={{
        gridColumn: { xs: '1 / 5', md: '1 / 9' },
        gridRow: 'span 3',
      }}
    >
      <CardModuleUI
        disablePadding
        sx={{
          '&:last-child': {
            padding: '22px',
          },
        }}
      >
        <Typography
          as="h3"
          mb={1}
          color="#3A3A3A"
          lineHeight="1.2"
          fontWeight="600"
          fontSize="1.125rem"
        >
          {title}
        </Typography>
        <Typography fontSize="0.875rem" mb={2} color="#3A3A3A">
          {subtitle}
        </Typography>
        <Box
          sx={{
            display: 'grid',
            margin: '0 auto',
            rowGap: '8px',
          }}
        >
          {items.map((item, index) => {
            return (
              <NotificationItem
                key={index}
                icon={item.icon}
                color={item.color}
                isDisabled={item.isDisabled}
                contentText={item.contentText}
                handleOnClick={() => handleOnClick(item.type)}
              />
            );
          })}

          <Paragraph>
            {aditionalText}
            <Link
              to="/ventas"
              onClick={() =>
                createAmplitudeEventWithDevice(
                  amplitude_events.ventas_activas.event_name
                )
              }
            >
              listado de ventas.
            </Link>
          </Paragraph>
        </Box>
      </CardModuleUI>
    </Box>
  );
};

export default NotificationsCenter;
