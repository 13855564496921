import React, { Fragment, useState } from 'react';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import {
  Grid,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { InfoText } from '.';
import { SelectUI, ButtonUI, ModalUI } from '../../../components/common';

const BlockList = ({
  classes,
  bloques,
  toggleModalBorrar,
  handleChangePage,
  handleDisableEnableBlock,
  handleOrdenarBlocks,
}) => {
  let html;

  const onSortEnd = ({ args }) => {
    const { oldIndex, newIndex } = args;
    if (oldIndex !== newIndex) {
      let bloques_ord = arrayMove(bloques, oldIndex, newIndex);
      handleOrdenarBlocks(bloques_ord);
    }
  };

  const DragHandle = sortableHandle(() => (
    <Icon classes={{ root: classes.drag_icon }}>drag_indicator</Icon>
  ));

  const SortableItem = sortableElement(({ bloque, idx }) => {
    return (
      <ListItem
        ContainerComponent="div"
        key={bloque.bl_nombre.change_param}
        classes={{
          root: classes.list_item,
          container: classes.list_item_container,
        }}
      >
        <ListItemIcon classes={{ root: classes.list_item_icon }}>
          <DragHandle />
        </ListItemIcon>
        <ListItemText
          classes={{ root: classes.list_text_wrapper }}
          primary={
            <span className={classes.list_text}>{bloque.bl_nombre.value}</span>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="Visibilidad"
            classes={{ root: classes.action_button }}
            onClick={() => handleDisableEnableBlock(idx)}
            size="large"
          >
            <Icon classes={{ root: classes.action_icon }}>
              {bloque.bl_activo.value ? 'visibility' : 'visibility_off'}
            </Icon>
          </IconButton>
          <IconButton
            aria-label="Editar"
            classes={{ root: classes.action_button }}
            onClick={(e) => handleChangePage(e, idx)}
            size="large"
          >
            <Icon classes={{ root: classes.action_icon }}>edit</Icon>
          </IconButton>
          <IconButton
            aria-label="Borrar"
            classes={{ root: classes.action_button }}
            onClick={() => toggleModalBorrar(idx)}
            size="large"
          >
            <Icon classes={{ root: classes.action_icon }}>delete</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <List component="div" classes={{ root: classes.list }}>
        {children}
      </List>
    );
  });

  const renderizarBloques = (bloques) =>
    bloques.map((bloque, index) => (
      <SortableItem
        key={bloque.bl_nombre.change_param}
        index={index}
        idx={index}
        bloque={bloque}
      />
    ));

  html = bloques.length ? (
    <SortableContainer
      onSortEnd={(args) => onSortEnd({ args: args })}
      useDragHandle
    >
      {renderizarBloques(bloques)}
    </SortableContainer>
  ) : (
    <List classes={{ root: classes.list }}>
      <ListItem
        classes={{
          root: classes.list_item,
          container: classes.list_item_container,
        }}
      >
        <ListItemText
          classes={{ root: classes.list_text_wrapper }}
          primary={
            <span className={classes.list_text}>No hay secciones activas</span>
          }
        />
      </ListItem>
    </List>
  );

  return html;
};

const BlockAdd = (props) => {
  let { agregar, selects, handleChangeSelect, handleAgregarBlock } = props;

  return (
    <form onSubmit={handleAgregarBlock}>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <SelectUI
            data={agregar.bl_tipo}
            options={selects.bloques}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonUI
            type="submit"
            label="Agregar"
            variant="outlined"
            color="primary"
            size="small"
            disabled={agregar.bl_tipo.value === 0}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const BlockSettings_ = (props) => {
  let {
    classes,
    bloques,
    agregar,
    selects,
    handleChangeSelect,
    handleOrdenarBlocks,
    handleChangePage,
    handleBorrarBlock,
    handleAgregarBlock,
    handleDisableEnableBlock,
  } = props;

  //usado en componente html
  const [index, setModalState] = useState(null);
  const toggleModalBorrar = (index) => setModalState(index);

  return (
    <Fragment>
      <ModalUI
        open={index !== null}
        id="borrar"
        title="Borrar sección"
        modalHandler={() => toggleModalBorrar(null)}
        aditional_param="borrar"
        handleSubmit={(e) => {
          handleBorrarBlock(e, index);
          toggleModalBorrar(null);
        }}
        button_label="Borrar"
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography>
              ¿Querés borrar la sección? Esta acción no se podrá deshacer.
            </Typography>
          </Grid>
        </Grid>
      </ModalUI>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InfoText
            first
            title="Secciones"
            text="Las secciones son los diferentes componentes de tu sitio web"
          />
          <BlockList
            classes={classes}
            bloques={bloques}
            toggleModalBorrar={toggleModalBorrar}
            handleOrdenarBlocks={handleOrdenarBlocks}
            handleChangePage={handleChangePage}
            handleDisableEnableBlock={handleDisableEnableBlock}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoText
            title="Agregar nueva sección"
            text="Puedes agregar nuevas secciones a tu tienda"
          />
          <BlockAdd
            agregar={agregar}
            selects={selects}
            handleChangeSelect={handleChangeSelect}
            handleAgregarBlock={handleAgregarBlock}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const styles = (theme) => ({
  extension_panel: {
    border: '1px solid #e0e0e0',
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: '0px',
    },
    '&:last-child': {
      borderRadius: '0px',
    },
  },
  span: {
    display: 'block',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  list_item: {
    paddingLeft: '8px',
    backgroundColor: '#fff',
    outline: '1px solid #e0e0e0',
  },
  list_item_icon: {
    marginRight: '4px',
  },
  list: {
    padding: '0',
    listStyle: 'none',
  },
  list_item_container: {
    zIndex: 1301,
  },
  list_text_wrapper: {
    fontSize: '0.8rem',
    paddingLeft: '0px',
    paddingRight: '30px',
    userSelect: 'none',
  },
  list_text: {
    fontSize: '0.8rem',
  },
  action_icon: {
    fontSize: '15px',
  },
  action_button: {
    padding: '6px',
  },
  drag_icon: {
    cursor: 'move',
    fontSize: '20px',
  },
});

export const BlockSettings = withStyles(styles, { withTheme: true })(
  BlockSettings_
);
