import { combineReducers } from 'redux';
import {
  authReducer,
  tiendaReducer,
  metodospagoReducer,
  metodosenvioReducer,
  localReducer,
  enviopersonalizadoReducer,
  configuracionmayoristaReducer,
  clienteReducer,
  categoriaReducer,
  dominioReducer,
  dominiosReducer,
  ventaReducer,
  blogReducer,
  paginaReducer,
  productoReducer,
  cuponReducer,
  bloqueReducer,
  trackReducer,
  alertaReducer,
  alertReducer,
  betaReducer,
} from './';
import { splitReducer } from '@splitsoftware/splitio-redux';

const rootReducer = combineReducers({
  auth: authReducer,
  tienda: tiendaReducer,
  metodos_pago: metodospagoReducer,
  metodos_envio: metodosenvioReducer,
  locales: localReducer,
  envio_personalizado: enviopersonalizadoReducer,
  configuracion_mayorista: configuracionmayoristaReducer,
  clientes: clienteReducer,
  categorias: categoriaReducer,
  dominios: dominiosReducer,
  dominio: dominioReducer,
  ventas: ventaReducer,
  blog: blogReducer,
  paginas: paginaReducer,
  productos: productoReducer,
  cupones: cuponReducer,
  bloques: bloqueReducer,
  tracks: trackReducer,
  alerta: alertaReducer,
  showAlert: alertReducer,
  beta: betaReducer,
  splitio: splitReducer,
});

export default rootReducer;
